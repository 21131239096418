import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import { useDispatch, useSelector } from "react-redux";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import TextAreas from "../../components/form/TextAreas";
import Loading from "../../components/shared/GeneralComponents";
import SeoSection from "../../components/shared/seoSection";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import {
    getJobSeekerPageContentRequest,
    postJobSeekerPageContentRequest
} from "../../redux/contentManagementSlice";
import { HomeWrapped } from "./style";

const JobSeekerPage = () => {

    const dispatch = useDispatch();
    const { jobSeekerPageData, jobSeekerPageStatus, jobSeekerPageError } =
        useSelector((state) => state.contentManagement);
    const [form] = Form.useForm();

    //Seo Section states
    const [ogImageUrl, setOGImageUrl] = useState();
    const [ogImageFile, setOGImageFile] = useState();
    const [twitterImageUrl, setTwitterImageUrl] = useState();
    const [twitterImageFile, setTwitterImageFile] = useState();

    const contentPage = jobSeekerPageData?.contentPage?.detail;
    useEffect(() => {
        dispatch(getJobSeekerPageContentRequest(CONTENT_PAGES.JOB_SEEKER_PAGE));
    }, [dispatch]);
    useEffect(() => {
        if (jobSeekerPageData) {
            form.setFieldsValue({
                "detail[bannerSection][title]": contentPage?.bannerSection?.title,
                "detail[bannerSection][description]": contentPage?.bannerSection?.description,
                "detail[stateSection][title]": contentPage?.stateSection?.title,
                "detail[stateSection][description]": contentPage?.stateSection?.description,
                "detail[hospitalTypeSection][title]": contentPage?.hospitalTypeSection?.title,
                "detail[hospitalTypeSection][description]": contentPage?.hospitalTypeSection?.description,
                "detail[disciplineSection][title]": contentPage?.disciplineSection?.title,
                "detail[disciplineSection][description]": contentPage?.disciplineSection?.description,

                //Seo Values Set
                meta_title: jobSeekerPageData?.contentPage?.seo_keywords?.meta_title ?? "",
                meta_description: jobSeekerPageData?.contentPage?.seo_keywords?.meta_description ?? "",
                is_canonical: jobSeekerPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
                robots_meta: jobSeekerPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
                meta_author: jobSeekerPageData?.contentPage?.seo_keywords?.meta_author ?? "",
                og_type: jobSeekerPageData?.contentPage?.seo_keywords?.og_type ?? "",
                og_title: jobSeekerPageData?.contentPage?.seo_keywords?.og_title ?? "",
                og_description: jobSeekerPageData?.contentPage?.seo_keywords?.og_description ?? "",
                og_image_width: jobSeekerPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
                og_image_height: jobSeekerPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
                og_url: jobSeekerPageData?.contentPage?.seo_keywords?.og_url ?? "",
                og_site_name: jobSeekerPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
                og_locale: jobSeekerPageData?.contentPage?.seo_keywords?.og_locale ?? "",
                og_locale_alternate: jobSeekerPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
                twitter_card: jobSeekerPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
                twitter_site: jobSeekerPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
                twitter_title: jobSeekerPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
                twitter_description: jobSeekerPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
                twitter_url: jobSeekerPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
            });
            setTwitterImageUrl(jobSeekerPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
            setOGImageUrl(jobSeekerPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);

        }
    }, [jobSeekerPageData]);

    const onUpdate = (values) => {

        const data = {
            ...values,
            name: 'Job Seeker Page',
            "og_image": ogImageFile ?? "",
            "twitter_image": twitterImageFile ?? "",
            "is_canonical": values.is_canonical === true ? 1 : 0,
            _method: 'PUT'
        }
        dispatch(postJobSeekerPageContentRequest({ data, id: jobSeekerPageData?.contentPage?.id }))
    };

    return (
        <>
            {jobSeekerPageStatus === STATUSES.LOADING ? (
                <Loading />
            ) : jobSeekerPageStatus === STATUSES.ERROR ? (
                <div>{jobSeekerPageError}</div>
            ) : (
                <HomeWrapped>
                    <div className="row">
                        <div className="col-sm-12">
                            <Form
                                layout="vertical"
                                className="pt-3"
                                form={form}
                                onFinish={onUpdate}

                            >
                                <div className="row">
                                    <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                                        <h3 className="font-bold" >Job Seeker Page</h3>
                                        <SaveButton title="Update" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                                        {/* Banner Section */}
                                        {/* <HeadingTitle title="Banner Section" /> */}
                                        <BaseInput
                                            inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                            labelText="Banner Section Title"
                                            name="detail[bannerSection][title]"
                                            placeText="Banner Section Title"
                                        />
                                        <TextAreas
                                            labelText="Banner Section Description"
                                            name="detail[bannerSection][description]"
                                            placeText="Banner Section Description"
                                        />
                                        {/* State Section*/}
                                        <BaseInput
                                            inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                            labelText="State Section Title"
                                            name="detail[stateSection][title]"
                                            placeText="State Section Title"
                                        />
                                        <TextAreas
                                            labelText="State Section Description"
                                            name="detail[stateSection][description]"
                                            placeText="State Section Description"
                                        />
                                        {/* Hospital Type Section*/}
                                        <BaseInput
                                            inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                            labelText="Hospital Type Section Title"
                                            name="detail[hospitalTypeSection][title]"
                                            placeText="Hospital Type Section Title"
                                        />
                                        <TextAreas
                                            labelText="Hospital Type Section Description"
                                            name="detail[hospitalTypeSection][description]"
                                            placeText="Hospital Type Section Description"
                                        />
                                        {/* Discipline Type Section*/}
                                        <BaseInput
                                            inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                            labelText="Discipline Section Title"
                                            name="detail[disciplineSection][title]"
                                            placeText="Discipline Section Title"
                                        />
                                        <TextAreas
                                            labelText="Discipline Section Description"
                                            name="detail[disciplineSection][description]"
                                            placeText="Discipline Section Description"
                                        />

                                    </div>
                                    <SeoSection ogImageUrl={ogImageUrl}
                                        setOGImageFile={setOGImageFile}
                                        setOGImageUrl={setOGImageUrl}
                                        twitterImageUrl={twitterImageUrl}
                                        setTwitterImageFile={setTwitterImageFile}
                                        setTwitterImageUrl={setTwitterImageUrl} />
                                </div>
                            </Form>
                        </div>
                    </div>
                </HomeWrapped>
            )}
        </>
    );
};

export default JobSeekerPage;
