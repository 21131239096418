import React from "react";
import { ContentManagementWrapped } from "../style";
import SideBarTab from "../../components/tabs/SideBarTab";
import HomeContentPage from "./HomeContentPage";
import PrivacyPage from "./PrivacyPage";
import TermsPage from "./TermsPage";
import ContactPage from "./ContactPage";
import LoginPage from "./loginPage";
import RegisterPage from "./registerPage";
import ForgotPasswordPage from "./forgotPasswordPage";
import ResetPasswordPage from "./resetPasswordPage";
import SuccessResetPasswordPage from "./successResetPasswordPage";
import JobListPage from "./JobListPage";
import AboutPage from "./AboutPage";
import CookiePolicyPage from "./CookiePolicyPage";
import WhyWorkLocumTenensPage from "./WhyWorkLocumTenensPage";
import WhatLocumTenensPage from "./WhatLocumTenensPage";
import LocumTenensPage from "./LocumTenensPage";
import ImportanceLocumTenensPage from "./ImportanceLocumTenensPage";
import WebSiteFooter from "./WebSiteFooter";
import JobSeekerPage from "./jobSeekerPage";

const ContentManagement = () => {
  return (
    <>
      <ContentManagementWrapped>
        <div className="layout-content">
          <div className="row">
            <div className="col-lg-3 col-12">
              <SideBarTab />
            </div>
            <div className="col-lg-9 col-12">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  aria-labelledby="v-pills-home-tab"
                  role="tabpanel"
                >
                  <HomeContentPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <WhatLocumTenensPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-why-work-locum-tenens"
                  role="tabpanel"
                  aria-labelledby="v-pills-why-work-locum-tenens-tab"
                >
                  <WhyWorkLocumTenensPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-locum-tenens"
                  role="tabpanel"
                  aria-labelledby="v-pills-locum-tenens-tab"
                >
                  <LocumTenensPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-importance-locum-tenens"
                  role="tabpanel"
                  aria-labelledby="v-pills-importance-locum-tenens-tab"
                >
                  <ImportanceLocumTenensPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-about-us"
                  role="tabpanel"
                  aria-labelledby="v-pills-about-us-tab"
                >
                  <AboutPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-job-seeker"
                  role="tabpanel"
                  aria-labelledby="v-pills-job-seeker-tab"
                >
                  <JobSeekerPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-job-list"
                  role="tabpanel"
                  aria-labelledby="v-pills-contact-tab"
                >
                  <JobListPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-contact"
                  role="tabpanel"
                  aria-labelledby="v-pills-contact-tab"
                >
                  <ContactPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="terms"
                  role="tabpanel"
                  aria-labelledby="terms-tab"
                >
                  <TermsPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="privacy"
                  role="tabpanel"
                  aria-labelledby="privacy-tab"
                >
                  <PrivacyPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="cookie-policy"
                  role="tabpanel"
                  aria-labelledby="cookie-policy-tab"
                >
                  <CookiePolicyPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="footer"
                  role="tabpanel"
                  aria-labelledby="footer-tab"
                >
                  <WebSiteFooter />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-login"
                  role="tabpanel"
                  aria-labelledby="v-pills-login-tab"
                >
                  <LoginPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-register"
                  role="tabpanel"
                  aria-labelledby="v-pills-register-tab"
                >
                  <RegisterPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-forgot-password"
                  role="tabpanel"
                  aria-labelledby="v-pills-forgot-password-tab"
                >
                  <ForgotPasswordPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-reset-password"
                  role="tabpanel"
                  aria-labelledby="v-pills-reset-password-tab"
                >
                  <ResetPasswordPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-success-reset-password"
                  role="tabpanel"
                  aria-labelledby="v-pills-success-reset-password-tab"
                >
                  <SuccessResetPasswordPage />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentManagementWrapped>
    </>
  );
};

export default ContentManagement;
